import * as React from "react"
import Seo from "../components/seo"
import { navigate } from "gatsby"
import { BLOG_SCREEN, HOME_SCREEN } from "../constant"

const NotFoundPage = (props) => {
  return (
    <>
      {typeof window !== 'undefined' && window.location.pathname === "/faq" ? navigate(HOME_SCREEN) : typeof window !== 'undefined' && window.location.pathname === "/blog" ? window.location.pathname = BLOG_SCREEN :
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Seo title="404: Not found" />
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      }
    </>
  )
}

export default NotFoundPage